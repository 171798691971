import { useEffect, useState } from 'react'
import packageJson from '@@/package.json'
import Bugsnag from '@bugsnag/js'
import { useQuery } from '@tanstack/react-query'
import useWebSocket from 'react-use-websocket'
import type { Options } from 'react-use-websocket'
import * as RenoService from '@nordic-web/rest-codegen/generated/reno'
import { brandConfig } from '@/config/brand'
import { useAuthState } from '@/features/auth/context/auth-state-context'
import type { RenoNotification } from '@/features/reno/types'
import { nextConfig } from '@/helpers/env'

/** The time between two long polling requests */
const RENO_POLLING_INTERVAL = 20_000

RenoService.OpenAPI.BASE = nextConfig.string('RENO_API')
RenoService.OpenAPI.HEADERS = {
  'client-name': brandConfig.clientName,
  'client-version': packageJson.version,
}

/** The timestamp of the last seen notifications, it's used to only fetch newer notifications */
type LastSeen = number

export const useReno = (lastSeen: LastSeen) => {
  const { accessToken, hasValidAccessToken } = useAuthState()
  const [isSocketConnected, setIsSocketConnected] = useState(false)

  const { data: renoData, error } = useQuery({
    queryKey: ['reno', { accessToken, lastSeen }],
    enabled: hasValidAccessToken && !isSocketConnected,
    staleTime: Infinity,
    refetchInterval: hasValidAccessToken ? RENO_POLLING_INTERVAL : false,
    queryFn: ({ queryKey }) => {
      const [, context] = queryKey

      if (!context || typeof context === 'string') {
        throw new Error('Invalid context')
      }

      RenoService.OpenAPI.TOKEN = context.accessToken
      return RenoService.getNotifications({
        since: context.lastSeen,
      })
    },
  })

  useEffect(() => {
    if (error) {
      Bugsnag.notify('An error occurred when fetching reno notifications ', (event) => {
        event.addMetadata('details', {
          rawError: error,
          hasValidAccessToken,
          lastSeen,
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  const socketUrl = renoData?.realtimeStream?.url ?? null
  const shouldConnect = hasValidAccessToken && !!socketUrl

  const options: Options = {
    queryParams: {
      token: accessToken ?? '',
    },
    onOpen: () => {
      setIsSocketConnected(true)
    },
    onClose: () => {
      setIsSocketConnected(false)
    },
    retryOnError: true,
    reconnectAttempts: 15,
    heartbeat: {
      interval: 60_000 * 5,
      timeout: 60_000 * 10,
    },
    reconnectInterval: (lastAttemptNumber: number) => Math.min(1000 * 2 ** lastAttemptNumber),
  }

  const socket = useWebSocket<RenoNotification>(socketUrl, options, shouldConnect)

  return { socketNotification: socket.lastJsonMessage, httpNotifications: renoData?.notifications }
}
