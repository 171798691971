import type { PropsWithChildren } from 'react'
import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import Bugsnag from '@bugsnag/js'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { ApiError } from '@nordic-web/rest-codegen/generated/auth'
import * as AuthService from '@nordic-web/rest-codegen/generated/auth'
import { parseJwt } from '@nordic-web/utils/authentication/token'
import { isClientSide } from '@nordic-web/utils/misc/detect-side'
import { brandConfig } from '@/config/brand'
import { getStaleAndCacheTime } from '@/features/auth/helpers/get-stale-and-cache-time'
import {
  REFRESH_COOKIE_NAME,
  getExpireTime,
  getRefreshTokenFromCookie,
  removeRefreshTokenFromCookie,
} from '@/features/auth/tokens/token-utils'
import type { TokenPair } from '@/features/auth/types'
import { useRenoNotification } from '@/features/reno/hooks/use-reno-notification'
import { nextConfig } from '@/helpers/env'
import { paths } from '@/helpers/paths'
import { useLocalStorage } from '@/hooks/use-local-storage'
import { setCookie, withDefaultCookieOptions } from '@/utils/cookies'

const PROFILE_TYPE_DELIMETER = '|'

AuthService.OpenAPI.BASE = nextConfig.string('AUTH_API')

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    authenticationTokens?: TokenPair
  }
}

const oneYearInSeconds = 60 * 60 * 24 * 365
const oneHourInMilliseconds = 60 * 60 * 1000

type AuthState = {
  isLoggedIn: boolean
  refreshToken?: string
  accessToken?: string
  hasValidAccessToken: boolean
  profileId?: string
  login: (tokenPair: TokenPair) => void
  logout: () => void
  changeProfile: (args: ChangeProfileArgs) => void
  refetchAccessToken: () => Promise<string | undefined>
}

type ChangeProfileArgs = {
  profileId: string
  isChild: boolean
  reloadPage: boolean
}

export const refreshAccessTokenKey = (refreshToken: string | undefined) => ['accessToken', { refreshToken }]

const logout = () => {
  removeRefreshTokenFromCookie()
  window.location.href = '/'
}

export const AuthStateContext = createContext<AuthState | undefined>(undefined)

export const AuthStateProvider = ({ children }: PropsWithChildren) => {
  const [refreshToken, setRefreshToken] = useState(() => getRefreshTokenFromCookie() || '')
  const isLoggedIn = !!refreshToken
  const queryClient = useQueryClient()
  const [profileId, setProfileId] = useLocalStorage<string | undefined>('profileId', undefined)

  const changeProfile = ({ isChild, profileId, reloadPage }: ChangeProfileArgs) => {
    if (isChild) {
      // We need to send isChild:boolean to the auth api when fetching an access token. To always make sure this boolean is not lost, we
      // bake it into the profile ID and split on the dilimiter before sending it to the API.
      setProfileId(`${profileId}${PROFILE_TYPE_DELIMETER}isChild`)
    } else {
      setProfileId(profileId)
    }

    if (reloadPage) {
      if (isChild) {
        window.location.href = paths.start.urlString()
      } else {
        window.location.reload()
      }
    }
  }

  useRenoNotification('LOGOUT_ALL', () => logout())

  const login = useCallback(
    (tokenPair: TokenPair) => {
      if (tokenPair) {
        const expireTime = getExpireTime(tokenPair.accessToken) || 0
        queryClient.setQueryDefaults(refreshAccessTokenKey(tokenPair.refreshToken), getStaleAndCacheTime(expireTime))
        queryClient.setQueryData(refreshAccessTokenKey(tokenPair.refreshToken), tokenPair.accessToken, {
          updatedAt: Date.now(),
        })

        setCookie(
          null,
          REFRESH_COOKIE_NAME,
          tokenPair.refreshToken,
          withDefaultCookieOptions({
            maxAge: oneYearInSeconds,
            sameSite: 'lax',
          })
        )
        setRefreshToken(tokenPair.refreshToken)
      }
    },
    [queryClient]
  )

  const {
    data: accessToken,
    isStale,
    refetch: refetchAccessToken,
  } = useQuery({
    queryKey: refreshAccessTokenKey(refreshToken),
    enabled: isLoggedIn,
    // When the query gets stale it will not refetch the data until the user interacts with the page, thats the reason we add an interval as well
    refetchInterval: isLoggedIn ? oneHourInMilliseconds : false,
    queryFn: async ({ queryKey }) => {
      const [, context] = queryKey

      if (typeof context === 'string') {
        throw new Error('Context is a string')
      }

      if (!context?.refreshToken) {
        Bugsnag.notify('Attempting to fetch access token without refresh token', (event) => {
          event.addMetadata('details', {
            isLoggedIn,
          })
        })
      }

      const [profile_id, is_child] = profileId ? profileId.split(PROFILE_TYPE_DELIMETER) : []

      try {
        const data = await AuthService.authenticationWebRefreshControllerRefresh({
          payload: {
            refresh_token: context?.refreshToken || '',
            client_id: brandConfig.clientName,
            ...(profile_id ? { profile_id } : {}), // The API didn't like a undefined or empty string value on key profile_id
            is_child: !!is_child,
          },
        })

        const accessToken = data.access_token

        const expireTime = (accessToken && getExpireTime(accessToken)) || 0
        queryClient.setQueryDefaults(refreshAccessTokenKey(refreshToken), getStaleAndCacheTime(expireTime))

        return accessToken
      } catch (error) {
        if (error instanceof ApiError) {
          if (error.status === 403) {
            Bugsnag.notify('A user got denied refreshing access token with status 403')
          }

          if (error.status === 401) {
            const refreshTokenData = parseJwt(refreshToken)

            Bugsnag.notify('A user got logged out when refreshing the access token', (event) => {
              event.addMetadata('details', {
                exp: refreshTokenData?.exp,
                iat: refreshTokenData?.iat,
              })
            })

            logout()
          }
        }

        throw error
      }
    },
  })

  const hasValidAccessToken = isLoggedIn && !!accessToken && !isStale

  // This effect allows the native apps to login their webviews, by injecting the tokens into the window object on load
  useEffect(() => {
    if (isClientSide && window.authenticationTokens) {
      // Set default profile to avoid showing profile selector when opening the webview
      changeProfile({
        profileId: 'default',
        isChild: false,
        reloadPage: false,
      })
      login(window.authenticationTokens)
      window.authenticationTokens = undefined
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AuthStateContext.Provider
      value={{
        accessToken,
        hasValidAccessToken,
        refreshToken,
        isLoggedIn,
        profileId,
        changeProfile,
        refetchAccessToken: async () => {
          const response = await refetchAccessToken()
          return response.data
        },
        logout,
        login,
      }}
    >
      {children}
    </AuthStateContext.Provider>
  )
}

export const useAuthState = () => {
  const state = useContext(AuthStateContext)

  if (typeof state === 'undefined') {
    throw new Error('useAuthState must be used within a AuthStateContext')
  }

  return state
}
